import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2860.000000 3056.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,3056.000000) scale(0.100000,-0.100000)">
          <path d="M18589 25108 c-10 -48 -4 -416 8 -405 2 3 6 87 8 187 2 112 8 187 14
193 17 17 13 27 -9 27 -11 0 -20 -1 -21 -2z"/>
<path d="M18660 25105 c0 -3 18 -14 40 -25 22 -11 40 -17 40 -12 0 5 -10 13
-22 19 -31 15 -58 23 -58 18z"/>
<path d="M18761 25060 c14 -31 19 -36 19 -24 0 6 -7 19 -16 30 -14 18 -14 18
-3 -6z"/>
<path d="M19480 24875 c0 -9 -6 -12 -15 -9 -17 7 -21 -11 -5 -21 6 -3 10 -15
10 -26 0 -10 -4 -19 -10 -19 -5 0 -10 3 -10 8 0 4 -119 7 -265 7 -198 0 -267
3 -277 13 -15 15 -95 16 -100 1 -2 -7 -12 -7 -31 0 -17 7 -25 15 -21 23 4 7 3
8 -4 4 -7 -4 -12 -14 -12 -22 0 -8 3 -13 8 -10 4 2 18 -1 31 -8 19 -11 21 -14
7 -19 -9 -4 -16 -2 -16 3 0 6 -8 10 -17 10 -16 0 -16 -2 -3 -11 10 -7 11 -12
4 -15 -13 -5 -12 -23 8 -239 13 -151 11 -2852 -3 -2928 -5 -29 -12 -173 -15
-322 -6 -238 -5 -276 10 -319 14 -42 17 -94 16 -360 0 -170 -4 -312 -9 -315
-31 -20 -41 -2447 -11 -2581 10 -43 10 -186 0 -675 -9 -482 -9 -738 1 -1150
16 -699 16 -874 1 -1034 -11 -124 -21 -161 -43 -161 -21 0 -87 73 -99 110 -14
42 -97 135 -130 145 -10 3 -47 34 -82 69 -69 68 -75 75 -138 162 -54 74 -91
115 -100 109 -10 -6 -270 274 -270 292 0 8 -73 81 -181 179 -14 13 -32 36 -39
51 -49 97 -153 223 -185 223 -13 0 -95 93 -137 155 -66 98 -84 121 -146 185
-35 36 -86 91 -115 123 -29 33 -89 99 -134 149 -46 50 -83 95 -83 102 -1 6
-30 43 -66 81 -103 110 -115 137 -121 259 -6 109 5 180 38 251 24 54 77 245
97 355 6 30 16 69 23 85 14 35 15 42 24 155 6 87 31 157 53 152 7 -1 12 9 12
23 0 14 4 25 8 25 9 0 23 216 26 405 1 61 3 130 5 155 l3 45 14 -40 c11 -35
13 4 11 311 -1 261 -5 355 -14 366 -14 17 -20 69 -28 288 -6 141 -20 264 -25
215 l-1 -20 -9 20 c-7 17 -9 16 -10 -10 l-2 -30 -9 35 c-4 19 -13 100 -19 180
-13 182 -19 227 -35 265 -7 17 -18 57 -25 90 -44 222 -57 271 -107 400 -36 92
-43 121 -68 290 -21 142 -36 200 -46 184 -5 -8 -9 -32 -9 -54 -1 -37 -2 -38
-12 -15 -5 14 -16 52 -24 85 -47 194 -59 235 -66 235 -4 0 -8 7 -8 16 0 9 -8
28 -19 42 -10 15 -24 43 -30 64 -7 20 -18 39 -26 42 -21 8 -52 74 -69 147 -16
69 -36 119 -48 119 -5 0 -8 6 -8 13 0 7 -13 28 -30 47 -16 19 -29 41 -30 48 0
7 -6 15 -14 18 -18 7 -52 59 -107 161 -80 147 -97 182 -91 188 3 3 0 5 -6 5
-7 0 -12 9 -12 21 0 12 -4 18 -11 13 -6 -3 -15 0 -20 7 -8 12 -11 12 -19 -1
-8 -13 -10 -12 -10 2 0 10 -5 17 -12 15 -6 -1 -14 7 -16 18 -2 11 -23 46 -46
79 -62 89 -74 110 -75 127 -1 8 -9 24 -19 35 -10 10 -31 42 -47 69 -16 28 -84
105 -152 172 -68 68 -123 128 -123 133 0 21 -121 145 -397 405 -64 61 -153
146 -197 191 -70 70 -116 103 -201 148 -11 6 -31 19 -45 29 -60 41 -161 107
-166 107 -5 0 -74 49 -84 60 -3 4 -27 22 -55 41 -27 18 -52 36 -55 40 -3 3
-22 13 -43 23 -21 9 -52 30 -70 45 -55 47 -94 71 -117 71 -12 0 -34 6 -48 14
-63 33 -142 67 -202 86 -36 11 -91 36 -122 55 -32 19 -77 40 -101 47 -23 6
-51 17 -62 24 -29 18 -119 50 -207 73 -43 12 -92 30 -110 41 -18 11 -51 22
-75 26 -23 3 -41 11 -40 17 1 6 -18 14 -43 18 -25 3 -76 12 -115 18 -38 7 -86
15 -105 17 -19 2 -48 9 -65 14 -16 5 -73 11 -126 13 -60 3 -107 10 -126 20
-16 9 -52 19 -79 22 -112 14 -217 35 -230 46 -10 8 -91 13 -257 16 -387 6
-1654 5 -1695 -1 -20 -3 -89 -8 -152 -11 -63 -4 -144 -12 -180 -20 -36 -7 -87
-16 -115 -20 -27 -4 -58 -11 -67 -16 -12 -6 -25 -6 -37 0 -13 7 -24 6 -37 -4
-11 -8 -26 -15 -34 -15 -14 0 -41 -31 -40 -45 1 -3 -6 -13 -15 -21 -8 -9 -15
-22 -15 -30 0 -8 -4 -14 -9 -14 -5 0 -16 -12 -24 -27 -7 -16 -31 -59 -53 -98
l-39 -70 0 -150 c0 -134 2 -156 23 -208 13 -32 48 -86 78 -120 30 -34 54 -66
54 -72 1 -16 55 -85 135 -170 235 -250 290 -310 374 -413 51 -62 113 -126 137
-143 24 -16 50 -42 58 -58 8 -15 22 -34 31 -41 9 -8 41 -46 71 -85 70 -91 103
-127 137 -150 16 -10 51 -53 79 -97 27 -43 60 -86 73 -96 12 -10 25 -27 29
-38 3 -11 28 -36 55 -57 28 -20 73 -65 102 -99 28 -35 75 -88 104 -118 28 -30
54 -61 56 -68 2 -7 27 -29 54 -50 36 -27 70 -69 121 -151 57 -90 81 -119 118
-143 61 -38 122 -99 173 -174 22 -32 63 -84 91 -114 28 -30 48 -59 45 -64 -3
-5 -1 -6 5 -2 7 4 12 4 12 -1 0 -4 34 -44 75 -87 41 -43 75 -85 75 -92 0 -7 6
-19 14 -26 7 -8 20 -27 29 -44 8 -16 38 -50 67 -74 128 -112 197 -194 295
-350 47 -76 182 -225 203 -225 13 0 114 -100 133 -132 11 -17 34 -47 51 -65
17 -18 48 -58 69 -88 21 -30 67 -86 104 -124 36 -38 72 -81 81 -97 8 -16 19
-29 24 -29 17 0 90 -72 90 -89 0 -9 23 -39 52 -67 29 -28 76 -78 103 -113 74
-92 114 -135 122 -128 10 11 33 -14 33 -37 0 -15 16 -32 51 -54 27 -19 59 -48
69 -65 10 -18 22 -36 27 -42 30 -37 73 -112 73 -129 0 -6 9 -22 20 -36 l20
-25 0 25 c0 20 2 22 11 10 8 -12 12 -12 16 -2 4 10 9 9 24 -4 10 -9 19 -22 19
-29 0 -6 11 -22 25 -35 14 -13 25 -28 25 -34 0 -6 16 -29 35 -50 19 -22 35
-43 35 -48 0 -4 6 -8 13 -8 13 -1 60 -71 71 -106 8 -26 88 -106 145 -143 36
-24 121 -134 121 -157 0 -17 91 -109 125 -126 44 -23 100 -79 147 -148 17 -25
43 -58 57 -73 14 -16 37 -52 51 -80 17 -33 37 -58 56 -67 28 -15 63 -49 130
-129 16 -19 46 -52 67 -74 21 -22 47 -56 57 -77 11 -21 34 -48 52 -61 43 -29
148 -136 148 -150 0 -28 39 -90 94 -149 34 -36 75 -81 92 -100 16 -19 50 -52
74 -73 69 -59 110 -100 110 -110 0 -5 6 -14 13 -22 6 -7 31 -40 55 -73 24 -33
52 -62 63 -65 10 -3 22 -13 25 -24 8 -20 81 -93 94 -93 7 0 96 -100 178 -202
36 -45 41 -86 18 -140 -7 -18 -19 -51 -26 -73 -7 -22 -20 -50 -29 -61 -9 -12
-22 -30 -28 -40 -88 -133 -107 -161 -118 -174 -7 -8 -30 -48 -50 -87 -21 -40
-42 -73 -47 -73 -4 0 -8 -7 -8 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -4 -10 -9 0
-6 -11 -22 -25 -37 -26 -28 -55 -79 -55 -97 0 -6 -12 -26 -27 -46 -43 -56 -71
-98 -99 -149 -41 -78 -67 -116 -83 -126 -29 -17 -76 -72 -106 -124 -16 -28
-34 -52 -40 -52 -17 -1 -115 -97 -115 -114 0 -23 -45 -49 -70 -41 -12 4 -28
24 -36 45 -10 24 -31 48 -55 65 -64 42 -235 229 -299 326 -8 12 -35 44 -60 69
-25 26 -72 74 -105 108 -32 34 -73 72 -90 83 -16 12 -41 37 -55 56 -100 138
-172 221 -333 387 -54 56 -115 119 -137 141 -41 43 -157 202 -183 250 -9 17
-35 46 -58 65 -51 42 -140 131 -191 191 -20 24 -45 54 -55 65 -64 75 -96 115
-131 163 -22 29 -53 63 -68 75 -48 36 -98 88 -142 149 -24 32 -47 55 -51 53
-4 -3 -22 20 -41 50 -18 30 -48 67 -66 82 -18 15 -61 63 -96 107 -35 44 -113
131 -174 192 -141 142 -164 169 -164 197 0 31 -92 136 -182 206 -46 35 -86 76
-97 98 -11 20 -33 51 -51 67 -17 17 -52 65 -78 107 -30 51 -60 87 -86 105 -62
42 -153 131 -196 193 -21 30 -55 71 -76 90 -20 19 -61 67 -90 107 -29 39 -98
117 -153 172 -64 64 -101 109 -101 123 0 22 -28 51 -70 73 -35 18 -82 72 -136
157 -61 95 -99 138 -143 161 -44 24 -124 100 -153 147 -12 19 -25 37 -28 40
-3 3 -22 30 -42 60 -35 52 -73 94 -168 185 -23 22 -62 70 -86 106 -25 37 -84
102 -132 145 -95 85 -137 135 -172 204 -13 27 -70 92 -140 160 -64 63 -125
131 -136 150 -10 19 -37 55 -59 80 -22 25 -62 73 -89 107 -40 51 -61 68 -120
96 -90 42 -136 58 -136 46 0 -5 5 -9 12 -9 9 0 9 -3 0 -12 -10 -10 -15 -10
-22 2 -7 10 -9 -17 -8 -80 1 -84 2 -89 8 -40 5 38 8 46 10 25 7 -77 7 -179 0
-275 -4 -58 -8 -85 -9 -60 -1 33 -4 41 -11 30 -5 -8 -9 -64 -8 -125 1 -60 3
-94 5 -75 2 19 6 51 10 70 l6 35 8 -35 c10 -46 13 -2909 3 -2935 -8 -19 -8
-19 -15 5 -4 14 -7 -65 -6 -175 0 -158 2 -185 8 -130 5 39 9 95 8 125 0 30 2
52 6 49 7 -8 13 -1119 6 -1119 -4 0 -5 -125 -4 -277 1 -153 -2 -302 -7 -332
-9 -50 -13 -2658 -5 -2781 7 -96 17 -155 29 -162 16 -11 66 -10 66 1 0 6 40
10 91 9 50 0 94 4 98 9 16 25 189 33 771 33 190 0 404 -4 475 -10 72 -5 148
-10 170 -11 35 -1 37 -2 15 -9 -22 -7 -21 -8 12 -9 20 -1 39 3 42 8 9 13 906
33 1403 31 51 0 126 9 195 24 62 13 132 27 155 30 98 13 221 55 231 79 2 4 11
7 20 7 10 1 42 14 72 30 30 17 61 30 69 30 15 0 45 9 136 40 28 10 78 21 113
25 34 4 68 12 75 19 7 6 18 12 25 14 14 3 61 34 97 63 11 9 63 31 116 48 52
17 120 47 149 66 30 20 91 46 136 60 44 14 92 33 105 42 40 29 68 43 87 43 11
0 35 15 55 32 19 18 52 47 73 63 20 17 44 38 53 48 8 9 20 17 25 17 6 0 24 13
41 29 82 80 241 138 304 111 17 -7 31 -16 31 -21 0 -4 33 -42 73 -83 40 -42
79 -86 87 -97 67 -97 139 -184 146 -176 4 3 22 -15 41 -40 33 -43 74 -86 98
-101 19 -13 73 -88 98 -138 14 -28 48 -69 78 -95 69 -59 80 -69 109 -109 57
-79 106 -103 198 -99 26 1 58 -3 71 -11 19 -10 91 -11 345 -5 447 10 461 11
473 18 18 11 3 45 -32 72 -19 13 -48 50 -66 81 -18 31 -40 64 -51 73 -10 8
-18 21 -18 28 0 7 -26 39 -57 71 -202 208 -299 317 -398 450 -11 15 -45 52
-75 83 -30 31 -77 83 -105 115 -27 32 -87 95 -132 140 -46 45 -83 87 -83 93 0
6 -4 11 -10 11 -5 0 -10 7 -10 15 0 8 -42 58 -94 110 -104 105 -113 123 -100
214 4 31 8 59 8 62 1 3 17 22 36 42 41 42 44 46 91 117 20 31 49 66 65 78 16
12 41 31 56 42 15 12 36 45 47 76 19 50 123 194 140 194 4 0 19 24 34 53 14
28 41 73 59 99 133 192 183 270 214 336 100 214 115 235 178 236 39 1 48 -5
114 -64 39 -36 102 -106 139 -155 37 -50 76 -96 88 -104 36 -26 93 -89 110
-121 9 -16 43 -57 75 -90 33 -33 60 -64 60 -68 0 -5 63 -73 140 -152 77 -79
140 -149 140 -154 0 -6 17 -33 38 -61 20 -27 54 -75 75 -107 32 -49 127 -138
146 -138 7 0 20 -17 127 -165 35 -49 78 -103 94 -120 133 -137 280 -320 280
-349 0 -10 23 -34 174 -181 22 -22 59 -63 81 -90 22 -27 48 -57 57 -67 9 -9
24 -28 34 -43 13 -20 19 -23 25 -13 7 10 9 10 9 -2 0 -8 39 -55 85 -105 47
-49 91 -105 97 -122 7 -18 16 -33 20 -33 4 0 8 -5 8 -11 0 -23 57 -89 90 -104
53 -24 125 -96 124 -123 -1 -16 10 -29 37 -46 22 -12 37 -25 34 -28 -3 -3 37
-13 88 -22 86 -17 102 -17 196 -3 65 10 109 21 121 32 12 11 29 16 45 13 14
-3 25 -1 25 3 0 5 11 9 23 9 24 0 57 28 57 48 0 6 18 30 39 54 22 23 37 47 34
53 -4 5 -3 7 1 3 6 -5 54 34 67 55 23 35 37 3707 19 4972 -6 402 -12 1987 -15
3523 -2 1536 -7 2797 -10 2802 -9 15 -25 12 -25 -5z m-713 -227 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-6697 -627 c25 -6 73 -10 108 -11 34
0 62 -4 62 -8 0 -5 24 -7 54 -4 35 3 89 -3 148 -17 51 -11 140 -28 198 -37 58
-8 130 -25 160 -36 98 -37 124 -46 170 -59 25 -7 51 -17 59 -23 8 -6 16 -10
18 -8 11 7 54 0 66 -12 8 -8 38 -19 68 -25 30 -5 74 -17 98 -25 24 -9 53 -16
65 -16 11 0 53 -12 91 -26 39 -14 90 -33 115 -43 68 -25 166 -94 203 -143 21
-28 55 -55 99 -78 36 -19 85 -47 107 -62 22 -16 45 -28 51 -28 5 0 10 -4 10
-10 0 -5 6 -10 14 -10 8 0 46 -15 84 -33 71 -33 121 -80 123 -113 0 -11 3 -14
6 -6 2 6 9 12 14 12 4 0 6 -7 3 -15 -4 -9 0 -15 8 -15 8 0 21 -18 28 -40 7
-22 25 -47 39 -56 l26 -17 -25 29 -25 28 37 -27 c26 -19 49 -27 77 -27 37 0
47 -7 116 -74 41 -41 75 -82 75 -90 0 -9 6 -16 13 -17 9 0 9 -2 0 -6 -31 -12
84 -132 149 -156 9 -3 31 -27 50 -53 18 -25 56 -72 83 -103 60 -66 135 -166
135 -179 0 -5 8 -16 17 -23 9 -8 58 -66 109 -129 51 -63 109 -133 128 -156 19
-22 37 -52 39 -65 12 -65 29 -109 42 -109 8 0 19 -8 24 -17 8 -13 10 -14 11
-4 0 8 3 12 6 9 3 -4 4 -8 2 -10 -2 -2 9 -16 25 -31 15 -16 27 -33 27 -40 0
-7 13 -28 28 -47 16 -19 38 -57 51 -85 59 -133 106 -215 140 -241 7 -6 20 -28
28 -50 14 -39 18 -50 46 -157 9 -32 30 -86 46 -120 17 -34 48 -114 70 -177 21
-63 46 -134 55 -157 18 -48 44 -168 51 -233 10 -94 28 -159 44 -165 36 -12 64
-110 76 -269 6 -88 24 -213 33 -236 6 -17 8 -45 21 -325 7 -149 15 -222 31
-286 20 -78 21 -94 10 -160 -15 -96 -31 -389 -29 -552 0 -86 -3 -133 -11 -143
-8 -8 -16 -76 -21 -166 -18 -294 -24 -340 -47 -378 -12 -19 -22 -42 -22 -51 0
-8 -6 -24 -14 -34 -13 -19 -15 -18 -57 10 -24 17 -49 32 -55 35 -16 8 -177
179 -190 202 -6 10 -34 43 -62 71 -67 67 -180 199 -220 258 -17 26 -59 71 -93
100 -78 68 -209 196 -209 204 0 3 -8 18 -18 33 -11 15 -32 47 -48 72 -28 43
-151 170 -165 170 -4 0 -19 21 -34 46 -15 25 -87 107 -161 181 -74 75 -150
159 -169 187 -19 28 -81 98 -139 156 -59 59 -106 113 -106 124 0 11 -3 16 -7
12 -4 -4 -16 8 -28 27 -26 44 -129 157 -288 319 -70 72 -137 149 -149 172 -11
22 -24 43 -29 46 -5 3 -15 21 -23 40 -14 34 -68 93 -189 210 -32 30 -70 73
-85 95 -77 114 -125 175 -138 175 -8 0 -14 5 -14 10 0 6 -6 10 -14 10 -18 0
-122 113 -163 177 -32 49 -104 136 -165 199 -33 33 -72 79 -86 101 -15 22 -51
68 -80 102 -28 34 -62 80 -75 102 -13 23 -32 42 -46 46 -28 7 -71 42 -71 57 0
5 -24 33 -52 62 -53 52 -82 84 -157 182 -23 28 -46 52 -50 52 -20 0 -72 64
-106 130 -24 45 -54 83 -88 111 -29 24 -59 53 -68 64 -37 45 -73 80 -81 77 -4
-1 -8 2 -8 7 0 5 -7 17 -17 26 -9 9 -28 34 -42 55 -14 21 -57 72 -96 113 -38
42 -75 81 -81 89 -6 7 -31 31 -55 53 -25 22 -64 67 -86 100 -23 33 -66 85 -97
115 -31 30 -56 61 -56 69 0 8 -14 36 -31 62 -27 42 -34 47 -60 44 -24 -3 -32
1 -39 18 -4 12 -27 42 -50 67 -23 25 -49 55 -57 67 -8 13 -20 20 -26 16 -6 -4
-8 0 -4 11 4 9 2 22 -3 29 -45 53 -63 76 -81 102 -20 29 -60 55 -84 55 -7 0
-27 21 -45 48 -19 26 -44 58 -56 72 -88 100 -122 144 -145 183 -20 33 -35 47
-58 52 -36 8 -106 82 -108 115 -1 12 -13 39 -27 59 -14 20 -39 56 -55 80 -43
61 -41 86 7 96 161 31 214 33 802 29 340 -2 614 -8 640 -13z m-1946 -4525 c17
-19 51 -76 76 -125 35 -70 51 -93 70 -98 14 -3 27 -9 30 -12 3 -3 22 -19 42
-34 21 -15 35 -33 32 -40 -3 -8 14 -25 40 -42 25 -16 44 -33 43 -39 -1 -6 4
-10 11 -8 7 1 12 -8 12 -20 0 -13 8 -35 18 -49 47 -72 57 -86 79 -110 17 -18
32 -25 51 -23 14 1 33 -4 42 -11 8 -8 26 -25 41 -37 27 -24 69 -92 69 -110 0
-7 17 -32 38 -57 66 -81 91 -118 84 -126 -4 -4 0 -5 8 -4 8 2 45 -13 81 -35
61 -35 69 -44 107 -120 23 -44 58 -108 77 -142 38 -66 148 -178 168 -171 16 5
61 -45 95 -105 15 -27 30 -48 35 -48 4 0 7 -6 7 -14 0 -8 9 -21 20 -29 11 -8
18 -16 15 -19 -3 -3 1 -11 10 -18 8 -7 15 -19 15 -26 0 -8 5 -14 11 -14 6 0 9
7 5 15 -3 8 -1 15 4 15 9 0 11 -5 14 -36 1 -6 43 -52 94 -102 50 -50 92 -96
92 -102 0 -9 47 -60 56 -60 2 0 1 8 -3 18 -5 13 0 11 20 -7 14 -13 29 -37 32
-52 4 -18 37 -60 85 -107 43 -43 85 -92 93 -108 14 -26 72 -97 122 -148 20
-21 72 -35 60 -17 -3 5 0 13 6 17 8 4 9 3 5 -4 -4 -7 -3 -12 1 -12 5 0 24 -26
43 -57 19 -32 51 -76 72 -98 21 -21 38 -44 38 -51 0 -6 8 -15 17 -18 15 -6 15
-8 3 -16 -8 -6 -10 -10 -4 -10 7 0 15 -11 18 -25 4 -14 10 -25 15 -25 5 0 31
-20 59 -45 30 -27 53 -41 57 -35 9 15 25 2 25 -20 0 -10 20 -40 45 -67 25 -26
45 -52 45 -58 0 -5 22 -32 49 -60 28 -27 63 -69 78 -92 40 -59 99 -128 165
-193 31 -30 73 -75 93 -100 20 -25 103 -114 183 -199 107 -111 157 -172 183
-221 41 -79 65 -105 93 -105 15 0 21 -7 22 -25 1 -16 14 -34 35 -50 59 -44
156 -146 192 -202 19 -30 62 -79 96 -109 34 -30 79 -71 99 -93 20 -21 63 -65
95 -98 31 -33 57 -66 57 -74 0 -8 5 -14 10 -14 14 0 1 -70 -16 -87 -7 -7 -15
-26 -19 -42 -7 -35 -29 -55 -125 -117 -107 -70 -107 -70 -190 -111 -41 -20
-109 -60 -152 -89 -51 -35 -101 -60 -150 -74 -40 -11 -113 -40 -163 -64 -117
-57 -160 -76 -177 -76 -7 0 -44 -16 -83 -35 -92 -47 -205 -75 -272 -68 -30 3
-82 -1 -125 -10 -126 -28 -189 -37 -336 -52 -79 -8 -151 -19 -160 -24 -44 -23
-237 -31 -779 -30 -312 0 -737 0 -943 1 l-374 0 -38 41 c-42 46 -44 60 -23
130 11 36 16 126 20 335 7 328 8 2879 2 3197 -3 126 -2 162 2 90 4 -66 6 116
5 405 -1 289 -5 607 -9 708 -6 133 -4 182 4 182 8 0 9 34 4 123 -8 155 -7 170
12 207 21 40 49 39 89 -4z m6103 -438 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2
-10 2 -28 0 -40z m750 -210 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m-770 -90 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-6190
-160 c-2 -24 -4 -5 -4 42 0 47 2 66 4 43 2 -24 2 -62 0 -85z m-10 -1100 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M10034 18360 c0 -69 1 -97 3 -62 2 34 2 90 0 125 -2 34 -3 6 -3 -63z"/>
<path d="M9204 21631 c21 -23 26 -26 26 -11 0 5 -10 15 -22 21 -23 12 -23 12
-4 -10z"/>
<path d="M9242 21255 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M9254 16960 c0 -135 2 -190 3 -122 2 67 2 177 0 245 -1 67 -3 12 -3
-123z"/>
<path d="M9261 16624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M11298 13263 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M12469 11237 c-2 -306 -1 -1077 1 -1169 0 -5 19 -8 43 -9 34 0 38 -2
22 -10 -13 -6 14 -8 80 -4 77 4 103 9 113 22 14 19 16 5 2 -17 -7 -12 -47 -16
-197 -21 l-188 -6 217 -1 c199 -2 217 -1 221 16 9 31 4 768 -4 747 -6 -14 -7
-11 -3 12 3 17 0 41 -6 53 -14 26 -21 188 -8 196 15 9 53 -22 70 -56 8 -17 19
-32 24 -33 5 -1 7 -8 6 -15 -2 -8 14 -31 35 -51 45 -45 99 -113 96 -123 -1 -3
11 -21 27 -38 16 -17 46 -63 67 -101 20 -38 40 -69 44 -69 12 0 69 -63 69 -77
0 -7 5 -13 10 -13 6 0 10 -7 10 -15 0 -18 100 -165 112 -165 4 0 8 -6 8 -12 0
-22 44 -102 73 -132 15 -16 27 -35 27 -42 0 -8 9 -14 20 -14 11 0 23 -7 26
-15 4 -8 13 -15 21 -15 8 0 12 4 9 8 -3 5 16 6 42 2 26 -4 65 -6 87 -5 22 2
56 3 76 4 l35 1 0 784 c-1 431 -4 788 -8 792 -14 14 -38 -19 -39 -55 -1 -27
-3 -30 -6 -11 -6 31 -23 42 -41 28 -9 -8 -16 -8 -24 0 -7 7 -21 12 -32 12 -27
0 -86 -22 -86 -33 0 -4 -4 -5 -10 -2 -11 7 -13 -7 -14 -147 -1 -54 1 -98 4
-98 3 0 4 -129 3 -288 -1 -206 -5 -308 -16 -362 l-14 -75 -4 95 c-2 52 -3 115
-2 140 1 25 -2 115 -5 200 l-7 155 -6 -280 c-3 -154 -2 -302 3 -329 10 -58 4
-69 -19 -36 -9 13 -25 32 -35 42 -10 10 -23 39 -30 65 -6 26 -20 57 -31 68
-42 44 -157 215 -203 302 -32 60 -60 99 -82 114 -19 12 -41 37 -50 55 -25 49
-85 148 -92 154 -17 13 -92 134 -114 183 -13 30 -33 60 -44 66 -24 13 -102 15
-102 3 0 -4 -10 -8 -22 -9 -36 -2 -128 18 -128 27 0 4 -6 10 -14 13 -12 5 -15
-55 -17 -406z m1048 -109 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-747 -1063 c0 -15 -6 -25 -15 -25 -12 0 -13 4 -3 25 6 14 13 25 15 25 1 0 3
-11 3 -25z"/>
<path d="M13445 11531 c-4 -61 -4 -138 1 -173 7 -60 8 -61 15 -28 15 65 27
263 18 287 -17 46 -27 21 -34 -86z"/>
<path d="M14161 11624 c9 -11 8 -14 -4 -14 -21 0 -30 -42 -12 -56 10 -8 13
-25 10 -63 -3 -29 -7 -57 -11 -62 -3 -5 0 -17 7 -25 8 -10 9 -18 3 -22 -12 -8
-10 2 -23 -197 -13 -197 -14 -565 -2 -565 5 0 12 -30 16 -67 3 -38 10 -86 15
-108 5 -22 5 -44 1 -48 -5 -5 -7 -37 -4 -70 3 -49 2 -58 -7 -44 -6 9 -8 27 -5
40 5 17 4 19 -4 7 -6 -8 -11 -62 -11 -119 0 -85 3 -108 18 -124 9 -11 12 -17
5 -13 -7 4 -17 -2 -23 -14 -8 -16 -8 -20 4 -20 8 0 16 5 18 11 8 25 408 35
1073 26 28 0 70 -1 95 -1 25 0 79 3 120 7 61 6 81 4 107 -10 28 -15 42 -15
150 -3 65 7 121 18 125 24 4 6 8 21 8 34 0 13 5 33 12 45 7 12 13 29 14 37 2
8 12 35 23 60 10 25 24 59 30 75 13 34 89 87 124 87 12 0 63 0 112 1 235 2
295 0 355 -13 76 -16 106 -41 116 -96 3 -22 16 -59 27 -84 11 -25 22 -65 24
-90 4 -66 6 -80 14 -80 4 0 15 -3 25 -7 12 -4 15 -3 10 5 -5 8 -2 10 8 6 9 -3
16 -10 16 -15 0 -5 23 -9 50 -9 28 0 50 -4 50 -10 0 -5 12 -10 26 -10 14 0 23
4 19 10 -3 6 4 10 17 10 18 0 20 -2 8 -10 -13 -8 -12 -10 3 -10 9 0 17 4 17 9
0 5 14 12 31 16 16 4 28 12 26 18 -2 7 3 17 12 24 14 11 13 12 -6 13 -18 0
-24 9 -34 58 -14 64 -75 200 -113 251 -14 19 -26 42 -26 51 0 8 -9 40 -21 70
-11 30 -24 66 -28 80 -9 27 -42 110 -71 175 -31 69 -51 138 -57 190 -6 52 -32
114 -83 200 -69 115 -108 226 -125 353 -4 28 -13 56 -21 60 -8 4 -51 4 -97 -1
-90 -9 -124 -13 -162 -15 -28 -2 -55 -45 -55 -89 0 -15 -13 -47 -29 -71 -25
-36 -31 -40 -39 -27 -14 26 -45 -64 -37 -107 5 -28 -1 -46 -34 -103 -23 -38
-41 -75 -41 -81 0 -19 -52 -178 -70 -214 -16 -33 -54 -161 -69 -240 -11 -52
-53 -140 -68 -140 -7 0 -13 -6 -13 -14 0 -7 -11 -24 -24 -37 -16 -17 -23 -34
-21 -58 1 -20 -7 -51 -21 -75 -12 -22 -32 -58 -44 -79 -14 -27 -19 -48 -15
-71 10 -50 -8 -59 -95 -45 -73 11 -156 9 -330 -10 -92 -9 -140 -7 -140 9 0 4
27 29 59 56 33 27 61 58 63 69 4 26 69 60 80 42 6 -9 8 -9 8 1 0 6 5 12 11 12
6 0 19 10 30 23 l18 22 1 -25 c1 -34 64 96 86 176 40 146 26 457 -31 693 -4
13 -30 48 -58 76 -29 29 -63 65 -77 81 -32 36 -87 84 -97 84 -4 0 -25 17 -48
38 -29 29 -64 47 -130 70 -126 43 -135 44 -275 41 -200 -3 -250 -1 -250 15 0
4 -16 5 -35 2 -19 -3 -50 -9 -69 -12 -20 -3 -36 -1 -40 5 -4 6 -18 11 -32 11
-14 0 -23 5 -21 13 1 7 -9 12 -25 13 -22 1 -26 -2 -17 -12z m571 -263 c18 -5
62 -15 98 -21 135 -24 160 -50 220 -235 17 -55 36 -105 40 -110 14 -17 23
-245 10 -245 -8 0 -9 -11 -5 -32 4 -18 4 -29 -1 -25 -9 9 -36 -74 -36 -110 0
-13 -4 -20 -9 -17 -5 3 -6 -2 -3 -10 3 -9 2 -16 -3 -16 -5 0 -9 -12 -8 -27 1
-31 -19 -69 -50 -95 -21 -18 -30 -23 -110 -59 -27 -12 -52 -26 -55 -29 -5 -7
-47 -18 -167 -46 -67 -15 -75 -15 -130 1 -111 33 -112 33 -118 79 -6 51 -1
183 8 193 4 3 7 -1 7 -11 0 -9 4 -15 9 -11 6 3 12 -14 14 -37 2 -24 2 130 1
342 -5 475 -4 541 1 549 5 9 246 -14 287 -28z m1533 -171 c14 -27 25 -56 25
-64 0 -28 52 -144 76 -170 13 -14 24 -30 24 -36 0 -6 4 -18 9 -28 5 -9 19 -48
31 -86 11 -38 25 -77 30 -86 7 -14 1 -24 -32 -51 l-42 -33 -116 3 c-82 2 -120
7 -131 17 -8 8 -21 14 -28 14 -26 0 -71 31 -71 50 0 10 20 59 45 109 25 50 45
98 45 106 0 27 43 119 64 136 15 12 20 32 23 80 7 106 12 110 48 39z m-1828
-92 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m1080 -984 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M17055 11620 c-3 -5 -3 -10 2 -10 4 0 -3 -7 -16 -17 -19 -13 -28 -14
-42 -5 -16 10 -20 8 -26 -16 -10 -35 -10 -228 0 -238 11 -11 31 13 32 39 0 26
35 35 56 14 12 -11 38 -12 143 -6 99 7 135 6 160 -5 l33 -14 -3 -98 c-9 -344
-14 -951 -8 -1073 l7 -143 111 5 c164 8 204 23 173 64 -11 15 -11 36 3 293 7
137 9 298 4 445 -4 127 -8 273 -9 325 -1 52 -5 105 -9 116 -20 62 49 107 128
84 34 -9 64 -9 137 0 90 12 95 14 122 48 47 62 32 179 -23 175 -14 0 -27 -2
-30 -3 -3 -2 -48 -5 -100 -7 -52 -2 -139 -6 -193 -9 -65 -4 -102 -3 -110 5 -9
7 -67 11 -163 10 -82 0 -184 -1 -226 -1 -58 0 -81 4 -93 16 -18 18 -51 21 -60
6z"/>
<path d="M18650 11620 c0 -13 18 -22 24 -11 3 5 -1 11 -9 15 -8 3 -15 1 -15
-4z"/>
<path d="M18861 11611 c-16 -10 -47 -16 -90 -16 -36 0 -75 -5 -86 -12 -20 -11
-27 -13 -45 -13 -5 0 -10 -8 -10 -17 0 -15 2 -16 10 -3 17 26 12 -66 -6 -108
l-15 -37 -16 35 -15 35 -13 -47 c-7 -25 -16 -77 -19 -115 -9 -82 -23 -131 -46
-158 -18 -21 -34 -72 -45 -142 -4 -24 -17 -64 -29 -90 -35 -73 -51 -113 -71
-173 -10 -30 -24 -65 -31 -77 -8 -12 -14 -31 -14 -41 0 -19 -28 -53 -70 -84
-21 -15 -24 -27 -26 -92 -1 -41 -7 -91 -14 -110 -11 -34 -45 -64 -57 -52 -6 5
-19 -14 -39 -53 -4 -8 -2 -28 4 -45 10 -26 9 -33 -7 -49 -21 -22 -37 -23 -140
-12 -68 8 -74 7 -68 -9 4 -10 7 -20 7 -22 0 -2 8 -4 18 -4 13 0 14 -2 4 -9
-10 -6 -4 -10 21 -15 19 -3 41 -4 48 -2 40 13 70 16 65 7 -11 -17 80 -23 188
-13 58 6 109 13 114 16 6 3 13 20 16 38 3 18 12 45 20 59 7 14 16 39 20 55 4
16 13 43 20 59 8 17 22 49 31 72 24 58 50 67 156 54 46 -6 173 -13 281 -17
128 -4 201 -10 207 -18 12 -14 47 -120 55 -166 3 -19 13 -48 21 -64 9 -17 16
-49 17 -73 0 -41 1 -43 15 -23 8 12 19 17 26 13 18 -11 67 -15 152 -12 16 1
31 -3 32 -8 2 -6 15 -8 28 -5 19 3 26 12 30 41 3 20 8 44 10 54 2 9 0 17 -5
17 -13 0 -20 73 -8 87 8 11 7 12 -7 7 -15 -6 -17 -3 -13 14 4 12 2 20 -3 16
-5 -3 -9 6 -9 19 0 13 -12 46 -26 73 -14 27 -36 76 -49 109 -57 150 -80 208
-130 320 -29 66 -56 143 -60 170 -8 66 -32 139 -65 200 -71 130 -90 174 -120
275 -41 137 -96 197 -149 161z m-48 -422 c10 -23 21 -55 24 -73 3 -17 18 -57
34 -89 16 -32 29 -64 29 -72 0 -8 5 -15 10 -15 6 0 10 -9 10 -19 0 -11 7 -26
15 -35 8 -8 15 -21 15 -29 0 -9 5 -17 11 -19 6 -2 13 -18 15 -36 2 -18 13 -51
24 -74 22 -47 18 -58 -33 -81 -28 -13 -109 -15 -249 -8 -10 1 -18 7 -18 15 0
7 -15 17 -32 21 -18 4 -36 9 -40 10 -5 1 -12 3 -18 4 -5 0 -14 11 -21 22 -9
17 -7 26 8 48 35 47 101 175 114 221 7 25 21 57 30 71 10 14 21 50 24 80 13
114 20 122 48 58z m227 -759 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
4 10 6 0 11 -4 11 -10z m260 -340 c3 0 -3 -5 -13 -11 -18 -10 -37 -5 -37 12 0
7 12 7 50 -1z"/>
<path d="M18848 10703 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M11453 11612 c-48 -5 -81 -52 -132 -186 -24 -64 -47 -116 -52 -116
-4 0 -11 -16 -15 -35 -5 -27 -9 -33 -19 -25 -10 8 -14 4 -18 -17 -3 -16 -11
-46 -17 -68 -6 -22 -15 -60 -19 -85 -4 -25 -26 -85 -49 -133 -23 -49 -42 -105
-43 -125 -2 -21 -10 -53 -19 -72 -18 -36 -35 -99 -32 -113 2 -5 -2 -6 -8 -2
-6 4 -10 -4 -10 -19 0 -14 -5 -26 -11 -26 -6 0 -8 -7 -5 -16 4 -11 2 -15 -8
-11 -10 4 -17 -3 -21 -24 -4 -16 -11 -29 -16 -29 -5 0 -9 -7 -9 -16 0 -8 -4
-13 -8 -10 -5 3 -6 -8 -4 -25 3 -17 0 -29 -7 -29 -6 0 -11 -7 -11 -16 0 -8 -4
-12 -10 -9 -5 3 -10 -2 -10 -12 0 -11 -4 -23 -8 -28 -17 -20 -32 -94 -32 -158
0 -63 -1 -65 -29 -75 -20 -7 -72 -8 -155 -2 -98 7 -140 6 -193 -5 -81 -17
-179 -19 -189 -4 -7 12 38 62 96 105 22 16 40 34 40 40 0 13 78 49 98 45 6 -1
12 3 12 9 0 6 11 26 23 43 17 23 23 44 23 75 -1 27 4 50 14 60 34 36 45 128
45 362 0 212 -1 228 -23 283 -13 31 -29 63 -37 70 -7 7 -16 29 -20 48 -11 51
-33 99 -45 99 -6 0 -16 5 -23 10 -62 52 -175 134 -230 167 -65 40 -70 41 -186
48 -66 4 -175 4 -244 0 -93 -5 -130 -4 -148 6 -16 8 -57 12 -124 10 -55 -2
-110 -3 -121 -3 -46 0 -47 -12 -46 -724 1 -369 2 -675 3 -680 3 -9 -8 -74 -17
-105 -3 -10 -15 -16 -29 -16 -31 0 -60 -22 -46 -36 6 -6 28 -5 61 2 28 6 69
11 90 11 22 0 40 4 40 9 0 11 72 23 82 13 8 -8 530 -4 572 5 17 3 31 1 36 -6
5 -9 47 -12 146 -12 77 0 141 -4 144 -9 9 -14 77 7 84 26 4 10 14 14 26 11 11
-3 27 2 36 10 13 14 21 14 57 3 23 -8 47 -20 54 -28 11 -13 37 -13 194 -4 107
7 187 16 195 23 18 15 18 40 1 33 -8 -3 -24 4 -38 14 l-23 20 34 20 c18 12 38
18 43 15 6 -4 8 5 5 20 -3 15 -1 24 3 21 5 -3 9 2 9 10 0 9 4 16 9 16 4 0 14
28 20 63 7 34 17 74 24 89 11 26 12 26 87 21 41 -3 131 -7 200 -9 69 -2 170
-7 224 -12 111 -11 126 -20 126 -81 0 -20 5 -43 10 -51 6 -10 10 -11 10 -2 0
22 20 12 20 -10 0 -13 5 -30 11 -38 8 -12 9 -9 4 10 -4 17 1 12 16 -14 21 -39
21 -49 3 -127 l-6 -26 113 -8 c62 -4 124 -11 137 -16 16 -6 29 -6 37 1 7 6 32
8 56 5 34 -4 39 -3 22 4 -30 14 -19 41 16 40 20 -1 22 -2 6 -6 -11 -3 -24 -9
-29 -14 -5 -4 6 -4 25 0 44 12 108 8 117 -6 5 -7 12 -6 22 5 13 14 12 15 -3 9
-11 -4 -17 -2 -17 7 0 8 6 17 13 19 7 3 5 6 -5 6 -10 1 -18 -3 -18 -8 0 -4
-12 -6 -26 -3 -19 3 -22 7 -12 13 10 7 9 9 -4 9 -9 0 -19 -4 -22 -9 -10 -16
-99 1 -125 25 -19 18 -23 29 -18 57 11 68 -46 215 -154 397 -21 36 -50 102
-63 145 -22 73 -36 109 -98 243 -10 24 -17 46 -14 49 3 3 1 13 -4 23 -5 9 -11
37 -14 61 -7 54 -31 127 -50 149 -8 8 -26 42 -41 75 -15 33 -33 67 -40 75 -6
8 -25 47 -40 85 -32 80 -64 112 -94 95 -26 -14 -57 -13 -84 3 -12 7 -37 11
-54 9z m-1528 -236 c174 -35 199 -42 248 -66 41 -20 57 -34 74 -70 12 -25 27
-50 33 -57 5 -7 10 -17 10 -23 0 -18 38 -97 55 -114 9 -8 18 -39 21 -68 3 -35
1 -46 -4 -33 -6 14 -11 -19 -15 -100 -3 -66 -14 -153 -25 -192 -10 -40 -15
-73 -12 -73 19 0 40 78 50 186 18 187 27 257 29 209 0 -23 5 -45 10 -50 5 -6
5 -40 0 -80 -5 -38 -11 -92 -14 -120 -3 -27 -11 -67 -16 -90 -6 -22 -13 -52
-16 -68 -3 -15 -14 -32 -24 -38 -15 -8 -18 -6 -13 8 3 10 -4 5 -16 -12 l-22
-30 6 28 c4 15 4 27 1 27 -7 0 -45 -82 -45 -98 0 -19 -70 -88 -97 -96 -13 -4
-23 -13 -23 -21 0 -14 -27 -26 -65 -30 -210 -24 -277 -28 -315 -16 -64 19 -73
42 -61 155 6 53 14 280 17 504 4 323 8 410 19 422 19 21 122 23 210 6z m1618
-188 c8 -18 21 -53 27 -78 7 -25 20 -61 30 -80 10 -19 28 -64 40 -100 11 -36
29 -76 39 -90 26 -38 47 -138 32 -153 -7 -7 -16 -13 -22 -13 -5 -1 -14 -2 -20
-3 -5 0 -7 -5 -4 -11 4 -6 -6 -10 -22 -11 -15 0 -37 -3 -48 -6 -24 -7 -120 -7
-155 0 -14 2 -31 5 -37 6 -7 0 -15 12 -19 26 -3 14 -12 25 -19 25 -34 0 -35
26 -6 116 15 49 33 100 38 114 6 14 16 43 23 65 7 22 18 47 25 55 7 8 16 30
20 49 9 44 44 121 54 121 5 0 16 -15 24 -32z"/>
<path d="M11420 10690 c14 -4 50 -8 80 -8 l55 1 -50 7 c-27 5 -63 8 -80 8 -29
0 -29 0 -5 -8z"/>
<path d="M13452 11250 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M18137 11063 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M11027 10691 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z"/>
<path d="M14123 10430 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M9079 10255 c-42 -24 -79 -51 -83 -60 -4 -11 4 -8 27 9 17 13 59 41
91 60 33 20 55 36 50 36 -5 0 -44 -20 -85 -45z"/>
<path d="M9166 10124 c-23 -22 -20 -49 10 -100 19 -32 32 -44 49 -44 38 0 36
14 -6 34 -37 18 -59 50 -44 64 4 4 41 9 83 12 41 3 77 10 79 16 10 32 -141 48
-171 18z"/>
<path d="M14009 10125 c-3 -2 -49 -5 -102 -6 -68 -1 -97 -5 -94 -13 2 -6 20
-10 42 -8 63 6 145 -7 145 -24 0 -10 13 -14 48 -13 26 0 42 3 36 5 -6 3 -10
18 -8 34 3 28 1 30 -30 30 -18 0 -35 -2 -37 -5z"/>
<path d="M13770 10106 c0 -7 7 -19 15 -26 16 -13 20 -3 9 24 -8 20 -24 21 -24
2z"/>
<path d="M17030 10095 c0 -16 2 -17 10 -5 6 10 10 11 10 3 0 -18 28 -16 46 2
17 16 17 16 -38 19 -22 2 -28 -2 -28 -19z"/>
<path d="M17795 10110 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M17160 10096 c25 -6 47 -8 50 -5 3 4 21 9 40 12 19 2 -3 4 -50 5 -84
0 -84 0 -40 -12z"/>
<path d="M17148 10073 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M19230 10051 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"/>
<path d="M16660 9889 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>


        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
